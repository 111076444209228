import Head from 'next/head'
import styles from './layout.module.css'
import utilStyles from '../styles/utils.module.css'
import Link from 'next/link'


const name = 'в 360°'
export const siteTitle = 'в360.рф'

export default function Layout({ children, home }) {
    return (
        <div className={styles.container}>
            <Head>
                <link href="/favicon.svg" rel="icon" type="image/svg+xml" />
                <meta
                    name="description"
                    content="Фотохостинг фото и видео 360. Сферические панорамы, фотографии и видео.
                    Виртуальные туры по квартирам, гостиницам и отелям."
                />
                <meta
                    property="og:image"
                    content={`https://og-image.now.sh/${encodeURI(
                        siteTitle
                    )}.png?theme=light&md=0&fontSize=75px&images=https%3A%2F%2Fxn--360-edd.xn--p1ai%2F%2Fimages%2F%2F360.svg`}
                />
                <meta name="og:title" content={siteTitle} />
                <meta name="twitter:card" content="summary_large_image" />
                <script
                    dangerouslySetInnerHTML={{
                        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-WFQPVT3');`,
                    }}
                />
            </Head>
            <header className={styles.header}>
                <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WFQPVT3" height="0" width="0" style={{display: 'none', visibility: 'hidden'}}></iframe></noscript>
                {home ? (
                    <>
                        <img
                            src="/images/360.svg"
                            className={`${styles.headerHomeImage}`}
                            alt={name}
                        />
                        <h1 className={utilStyles.heading2Xl}>3D туры по квартирам и домам</h1>
                    </>
                ) : (
                    <>
                        <Link href="/">
                            <a>
                                <img
                                    src="/images/360.svg"
                                    className={`${styles.headerImage} ${utilStyles.borderCircle}`}
                                    alt={name}
                                />
                            </a>
                        </Link>
                    </>
                )}
            </header>
            <main>{children}</main>
            {!home && (
                <div className={styles.backToHome}>
                    <Link href="/">
                        <a>← на Главную страницу</a>
                    </Link>
                </div>
            )}
        </div>
    )
}